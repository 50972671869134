<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <AppPageHeader
        subtitle="Visualize aqui o detalhamento da operação selecionada"
        title="Detalhes da operação"
      >
        <template #header-left>
          <div class="d-flex justify-content-end align-items-end">
            <b-button
              variant="outline-primary"
              size="sm"
              @click="$router.push({ name: 'concessoes.index' })"
            >Voltar</b-button>
          </div>
        </template>
      </AppPageHeader>
    </b-card>
    <b-card>
      <b-row
        v-if="
          nota_comercial.quitacao === 'Aguardando pagamento' ||
            nota_comercial.quitacao === 'Vencida'
        "
        align-v="end"
        align-h="between"
        class="p-1"
      >
        <h3 class="text-dark">
          Operação em processamento
        </h3>
        <b-button
          variant="primary"
          :to="{
            name: 'concessoes.liquidacao',
            params: { id: $route.params.id },
          }"
        >Fazer pagamento</b-button>
      </b-row>
      <b-row class="p-1">
        <b-alert
          v-if="nota_comercial.status === 'cancelada'"
          show
          variant="danger"
          class="p-1 border-danger mb-1 w-100 text-dark"
        >
          <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1 text-dark" />
          <b class="text-dark">Sua Nota Comercial foi cancelada.</b> <span class="text-dark">Entre em contato conosco para saber mais sobre
            este cancelamento ou repita a operação. </span>
        </b-alert>

        <b-alert
          v-if="nota_comercial.quitacao === 'Aguardando pagamento'"
          show
          variant="warning"
          class="p-1 border-warning mb-1 w-100 text-dark"
        >
          <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1 text-dark" />
          <b class="text-dark">Nota comercial aguardando pagamento.</b> <span class="text-dark">Faça hoje o seu pagamento e evite
            multa e encargos.</span>
        </b-alert>

        <b-alert
          v-if="nota_comercial.quitacao === 'Vencida'"
          show
          variant="danger"
          class="p-1 border-danger mb-1 w-100 text-dark"
        >
          <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1 text-dark" />
          <b class="text-dark">Nota comercial Vencida.</b> <span class="text-dark">Clique no botão fazer pagamento pra ter acesso aos valores atualizados.</span>
        </b-alert>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-card class="cc-bg-light mb-0 h-100">
            <div class="d-flex justify-content-between mb-1">
              <h5>Status
                <feather-icon
                  v-b-tooltip.hover
                  icon="InfoIcon"
                  size="14"
                  :title="tooltipMessage"
                  stroke="#093272"
                /></h5>
              <span class="d-flex justify-content-end">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="comercialNoteStatusBadge(nota_comercial.status)" />
              </span>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h5 class="mb-1 text-dark">
                Total a pagar no vencimento
              </h5>
              <h5 class="mb-1 text-dark">
                {{ moneyFormat(nota_comercial.valor_devido) }}
              </h5>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h5 class="mb-1">
                Total solicitado
              </h5>
              <h5 class="mb-1">
                {{ moneyFormat(nota_comercial.valor_emissao) }}
              </h5>
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="cc-bg-light mb-0 h-100">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Taxa efetiva no período
              </h6>
              <h6 class="mb-1">
                {{ nota_comercial.taxa_efetiva_periodo.toString().replace('.', ',') }}%
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Taxa de juros a.m.
              </h6>
              <h6 class="mb-1">
                {{ $store.state.auth.userData.empresa.taxa_juros.toString().replace('.', ',') }}%
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Taxa de escrituração
              </h6>
              <h6 class="mb-1">
                {{ $store.state.auth.userData.empresa.taxa_escrituracao.toString().replace('.', ',') }}%
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Prazo de vencimento (dias)
              </h6>
              <h6 class="mb-1"
                v-if="(nota_comercial.prazo !== null && nota_comercial.prazo !== '' && nota_comercial.prazo !== 0)"
              >
                {{ nota_comercial.prazo }}
              </h6>
              <h6 class="mb-1" v-else >
                {{ $store.state.auth.userData.empresa.prazo }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Data de vencimento
              </h6>
              <h6 class="mb-1">
                {{ formatDate(nota_comercial.data_vencimento) }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">
                Data de resgate
              </h6>
              <h6 class="mb-1">
                {{ formatDate(nota_comercial.data_emissao) }}
              </h6>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import AppPageHeader from '@core/components/app-page-header/AppPageHeader.vue'
import {
  BCard, BCol, BRow, BAlert, BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  moneyFormat,
  dateFormatter,
  percentageFormatter,
  statusBadgeVariant,
  comercialNoteStatusBadge,
} from '@core/comp-functions/data_visualization/datatable'
import format from 'date-fns/format'
import parse from 'date-fns/parse'

export default {
  name: 'ShowConcession',
  components: {
    AppPageHeader,
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
  },
  filters: {
    moneyFormat,
    dateFormatter,
    percentageFormatter,
    statusBadgeVariant,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      nota_comercial: {},
      statusesNames: {
        aguardando_transferencia: 'Processando',
        vencida: 'Vencida',
        executada: 'Depositado',
        liquidada: 'Liquidada',
        aguardando_assinatura: 'Aguardando assinatura',
        cancelada: 'Cancelada',
      },
      tooltipMessage:
        'Após a emissão da Nota Comercial a mesma deverá ser assinada até as 14:00h do mesmo dia. Se não houver a assinatura até este horário limite, a operação será automaticamente cancelada e poderá ser solicitada novamente no dia seguinte.',
    }
  },
  computed: {
    statusLabel() {
      return this.statusesNames[this.nota_comercial.status]
    },
    taxaEscrituracaoIsNullOrZero() {
      return (
        this.nota_comercial.taxa_escrituracao === '0.0'
        || this.nota_comercial.taxa_escrituracao === null
      )
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    async setData() {
      const { id } = this.$route.params
      try {
        const { data } = await this.$store.dispatch('concession/fetch', id)
        this.nota_comercial = data
      } catch (e) {
        if ([403, 404, 401].includes(e.response.status)) {
          this.$router.push({ name: 'misc-not-authorized' })
        }
      }
    },
    comercialNoteStatusBadge(status) {
      return comercialNoteStatusBadge(status)
    },
    moneyFormat(value, minimumFractionDigits = 2) {
      return moneyFormat(value, minimumFractionDigits)
    },
    formatDate(value) {
      if (!value) return ''

      const date = parse(value, 'yyyy-MM-dd', new Date())

      return format(date, 'dd/MM/yyyy')
    },
    formatDate(value) {
      if (!value) return "";

      let date = parse(value, "yyyy-MM-dd", new Date());

      return format(date, "dd/MM/yyyy");
    },
  },
}
</script>
